<template>
    <div>
        <div class="filter-panel">
            <template>
                <CSSelect style="margin-right: 0">
                    <el-date-picker
                            type="datetime"
                            placeholder="请选择开始时间"
                            style="width: 184px"
                            value-format="yyyy-MM-dd HH:mm"
                            format="yyyy-MM-dd HH:mm"
                            :editable="false"
                            v-model="startTime"
                    >
                    </el-date-picker>
                </CSSelect>
                <span class="inline-gray"></span>
                <CSSelect>
                    <el-date-picker
                            v-model="endTime"
                            type="datetime"
                            placeholder="请选择结束时间"
                            style="width: 184px"
                            value-format="yyyy-MM-dd HH:mm"
                            format="yyyy-MM-dd HH:mm"
                            :editable="false"
                    >
                    </el-date-picker>
                </CSSelect>
            </template>
            <input
                    type="text"
                    placeholder="搜索标题/资料来源"
                    style="margin-left: 0"
                    v-model="keyword"
                    class="cs-input"
            />
            <button
                    type="button"
                    class="btn btn-primary btn-sm"
                    @click="queryGuidance()"
            >
                查询
            </button>
        </div>

        <div class="result-panel">
            <CSTable :thead-top="filterHeight">
                <template v-slot:header>
                    <div class="table-header-panel" style="text-align: right">
                        <button
                                class="btn btn-primary sticky-right"
                                @click="addEditGuidance(1)"
                        >
                            <svg class="icon" aria-hidden="true">
                                <use xlink:href="#icon-menujiahao"></use>
                            </svg>
                            添加办事指南
                        </button>
                    </div>
                </template>
                <template v-slot:thead>
                    <tr>
                        <th>发布日期</th>
                        <th>标题</th>
                        <th>资料来源</th>
                        <th>操作</th>
                    </tr>
                </template>
                <template v-slot:tbody>
                    <tr v-for="item in noticeList" :key="item.id">
                        <td>
                            {{ item.uploadTime}}
                        </td>

                        <td>
                            <div
                                    class="allow-click"
                                    @click="queryGuidanceInfo(item)"
                                    style="
                  width: 200px;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                "
                            >
                                {{ item.title }}
                            </div>
                        </td>
                        <td>{{ item.address }}</td>

                        <td class="text-center">
                            <div class="btn-group">
                                <button
                                        type="button"
                                        class="btn btn-primary dropdown-toggle"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                >
                                    操作
                                </button>

                                <ul class="dropdown-menu">
                                    <li @click="addEditGuidance(2, item.id, item)">
                                        <a style="width: 100%">修改指南</a>
                                    </li>
                                    <li>
                                        <a
                                                style="width: 100%"
                                                @click="
                        deleteId = item.id;
                        guidanceVisible = true;
                        guidanceId = item.id
                      "
                                        >删除指南</a
                                        >
                                    </li>
                                </ul>
                            </div>
                        </td>
                    </tr>
                </template>
            </CSTable>
            <!-- 删除文件弹出框 -->
            <CSDialog
                    dialogTitle="提示"
                    dialog-header-class=" "
                    dialogWidth="480px"
                    dialog-confirm-btn-text="确定"
                    :dialogVisible="guidanceVisible"
                    @onClose="guidanceVisible = false;"
                    @onConfirm="DeleteDataFile"
                    dialog-header-icon="icon-menua-zu13"
            >
                <div
                        slot="dialog-content"
                        style="padding: 20px 30px; font-size: 24px; text-align: center"
                >
                    确定删除吗?
                </div>
            </CSDialog>
            <Pagination/>
        </div>

        <!-- 添加办事指南页面 -->
        <CSDialog
                :dialog-title="addGuidanceDialogTitle"
                dialog-width="920px"
                :dialog-visible="editGuidanceVisible"
                @onClose="addEditClose"
                @onConfirm="judgeAddEdit"
        >
            <template v-slot:dialog-content>
                <div style="padding: 20px 30px">
                    <div style="margin-bottom: 23px; font-size: 24px">
                        <div class="notice_title" style="width: 96px">标题</div>
                        <input
                                type="text"
                                v-model="editGuidanceByInfo.headline"
                                placeholder="请输入"
                                class="hy-input"
                        />
                    </div>
                    <div style="margin-bottom: 23px">
                        <div class="notice_title" style="width: 96px">发布日期</div>
                        <CSSelect i-width="36px" height="40px">
                            <el-date-picker
                                    class="dateTime"
                                    type="date"
                                    placeholder="请填写发布日期"
                                    style="width: 250px; font-size: 24px; line-height: 32px"
                                    value-format="yyyy-MM-dd"
                                    format="yyyy-MM-dd"
                                    :editable="false"
                                    v-model="editGuidanceByInfo.releaseDate"
                            >
                            </el-date-picker>
                        </CSSelect>
                    </div>

                    <div style="margin-bottom: 23px; display: flex">
                        <div class="notice_title" style="width: 96px">资料来源</div>
                        <input
                                type="text"
                                v-model="editGuidanceByInfo.fileSource"
                                placeholder="请输入"
                                class="hy-input"
                        />
                    </div>

                    <div style="display: flex; margin-bottom: 23px">
                        <div class="notice_title" style="width: 96px">正文</div>
                        <WangEditor
                                style="width: 720px"
                                parentComponent="editVisitingService"
                        ></WangEditor>
                    </div>
                    <div style="display: flex; font-size: 24px">
                        <div class="notice_title" style="width: 96px">附件</div>
                        <div style="width: 720px">
                            <div v-if="editGuidanceByInfo.accessory.length > 0">
                                <p
                                        v-for="(enclosure, index) in editGuidanceByInfo.accessory"
                                        :key="enclosure.url"
                                        style="margin-bottom: 10px"
                                >
                                    <a
                                            class="accessory"
                                            style="float: left"
                                            :href="enclosure.url"
                                            target="_blank"
                                    >{{ enclosure.name }}</a
                                    >
                                    <svg
                                            class="icon"
                                            aria-hidden="true"
                                            style="margin-left: 10px;float: left;margin-top: 10px"
                                            @click="deleteGuidanceFile(index)"
                                    >
                                        <use xlink:href="#icon-menushanchu"></use>
                                    </svg>
                                    <br>

                                </p>
                            </div>
                            <div>
                                <button
                                        style="
                    width: 144px;
                    padding: 0;
                    height: 40px;
                    font-size: 24px;
                    border-radius: 4px;
                    box-shadow: 0px 5px 10px rgba(0, 182, 148, 0.3);
                  "
                                        :style="editGuidanceByInfo.accessory.length > 0?'margin-top: 10px':''"
                                        class="btn btn-primary"
                                        @click="upLoadFile"
                                >
                                    <svg class="icon" aria-hidden="true">
                                        <use xlink:href="#icon-menujiahao"></use>
                                    </svg>
                                    上传附件
                                </button>
                            </div>
                        </div>
                    </div>
                    <input
                            type="file"
                            class="file"
                            id="up_load_file"
                            hidden
                            @change="chooseFile($event)"
                    />
                </div>
            </template>
        </CSDialog>

        <!-- 查看标题弹框 -->
        <CSDialog
                dialog-width="880px"
                :dialog-title="guidanceDetailsInfo.headline"
                dialog-header-class="alert-bg"
                :dialog-visible="guianceInfoVisible"
                :dialog-show-confirm-btn="false"
                dialog-cancel-btn-text="关闭"
                @onClose="guianceInfoVisible = false"
        >
            <template v-slot:dialog-content>

                <div style="padding: 30px 30px 16px 30px">
                    <div
                            v-html="guidanceDetailsInfo.content"
                            style="
              overflow-y: auto;
              padding-right: 15px;
              text-align: justify;
              height: 474px;
            "
                    ></div>
                    <div class="enclosure" style="margin-top: 30px;
              border-top: 1px solid #ccc;padding-top: 30px">
                        <div class="enclosure_label">附件</div>
                        <div
                                class="enclosure_name"
                                v-if="
                guidanceDetailsInfo.files &&
                guidanceDetailsInfo.files.length > 0
                && guidanceDetailsInfo.files[0] !== ''
              "
                        >
                            <p
                                    v-for="guidance in guidanceDetailsInfo.files"
                                    :key="guidance"
                                    style="width: 720px"
                            >
                                <a
                                        style="text-decoration: underline; color: #1dafff"
                                        :href="guidance"
                                        target="_blank"
                                > {{getFileName(guidance)}}</a
                                >

                            </p>
                        </div>
                        <div v-else>-</div>
                    </div>
                </div>
            </template>
        </CSDialog>


        <!--    <CSDialog-->
        <!--      dialog-width="880px"-->
        <!--      :dialog-title="guidanceInfoTitle"-->
        <!--      dialog-header-class="alert-bg"-->
        <!--      :dialog-visible="guidanceInfoVisible"-->
        <!--      :dialog-show-confirm-btn="false"-->
        <!--      dialog-cancel-btn-text="关闭"-->
        <!--      @onClose="guidanceInfoVisible = false"-->
        <!--    >-->
        <!--      <template v-slot:dialog-content>-->
        <!--        <div style="padding: 30px 10px 30px 30px">-->
        <!--          <div v-html="guidanceDetailsInfo"></div>-->
        <!--        </div>-->
        <!--      </template>-->
        <!--    </CSDialog>-->


        <!-- 删除指南弹框 -->
        <CSDialog
                dialogTitle="提示"
                dialog-header-class=" "
                dialogWidth="480px"
                dialog-confirm-btn-text="确定"
                :dialogVisible="guidanceToBeIssuedVisible"
                @onClose="guidanceToBeIssuedVisible = false"
                @onConfirm="deleteGuidanceToBeIssued"
                dialog-header-icon="icon-menua-zu13"
        >
            <div
                    slot="dialog-content"
                    style="padding: 20px 30px; font-size: 24px; text-align: center"
            >
                确定删除吗?
            </div>
        </CSDialog>
    </div>
</template>

<script>
    import {
        addGuidanceUrl,
        queryGuidanceList,
        deleteGuidanceUrl,
        editGuidanceUrl,
        queryGuidanceInfoUrl,
        getGuidanceUrl,
        showAllUrl,
        insertDataUrl,
        deleteFileNUrl,
        uploadSelectDataUrl,
    } from "@/requestUrl";
    import CSTable from "@/components/common/CSTable";
    import CSDialog from "@/components/common/CSDialog";
    import Pagination from "@/components/Pagination.vue";
    import ChooseLocation from "@/components/ChooseLocation";
    import DropDownSelection from "@/components/DropDownSelection.vue";
    import CSSelect from "@/components/common/CSSelect";
    import WangEditor from "@/components/WangEditor";
    import dayjs from "dayjs";

    export default {
        name: "guidance",
        components: {
            CSTable,
            Pagination,
            CSDialog,
            ChooseLocation,
            DropDownSelection,
            CSSelect,
            WangEditor,
        },
        data() {
            return {
                guidanceInfoVisible: false,
                guidanceInfoTitle: '',
                guidanceId: '',
                noticeList: [],
                guidanceToBeIssuedVisible: false,
                keyword: "",
                filterHeight: 0,
                guidanceList: [],
                // addGuidanceFlag: false, //添加指南
                guidance: {
                    id: "",
                    headline: "",
                    releaseDate: "",
                    fileSource: "",
                    content: "",
                    accessory: [],
                },
                // guidanceInfoFlag: false,
                // guidanceInfo: {},
                // editGuidanceFlag: false,
                // editGuidanceInfo: {},

                //添加指南弹窗文案
                addGuidanceDialogTitle: "",

                //修改办事指南弹窗的显隐
                editGuidanceVisible: false,
                //添加/修改的状态
                addEditState: "",
                //删除指南弹窗的显隐
                guidanceVisible: false,
                //指南查询开始时间
                startTime: dayjs().subtract(30, "day").format("YYYY-MM-DD 00:00"),
                //指南查询结束时间
                endTime: dayjs().format("YYYY-MM-DD") + " 23:59",
                // 删除项id
                deleteId: "",
                //指南列表
                guidancePublishedList: [],
                //指南详情信息
                guidanceDetailsInfo: {},
                //查看指南弹窗显隐
                guianceInfoVisible: false,
                // 修改指南
                editGuidanceByInfo: {
                    headline: "",
                    releaseDate: "",
                    fileSource: "",
                    content: "",
                    accessory: [],
                },
            };
        },
        created() {
            this.queryGuidance();
            this.$vc.on(
                this.$route.path,
                "pagination_page",
                "event",
                this.queryGuidance
            );
            this.$vc.on(
                this.$route.path,
                "editVisitingService",
                "changeEditor",
                this.changeEditor
            );
            window.addEventListener("keydown", this.queryFinanceListDown);
        },
        beforeDestroy() {
            window.removeEventListener("keydown", this.queryFinanceListDown);
        },
        deactivated() {
            window.removeEventListener("keydown", this.queryFinanceListDown);
        },
        methods: {
            queryFinanceListDown(e) {
                if (e.keyCode == 13) {
                    this.queryGuidance();
                }
            },
            DeleteDataFile() {
                this.$fly.delete(`${deleteFileNUrl}/${this.guidanceId}`).then(res => {
                    this.$vc.toast("删除成功");
                    this.guidanceVisible = false;
                    this.queryGuidance();
                });
            },
            changeEditor(html) {
                this.editGuidanceByInfo.content = html;
            },
            deleteGuidanceToBeIssued() {
            },
            upLoadFile() {
                $("#up_load_file").trigger("click");
            },
            chooseFile(event) {
                var photoFiles = event.target.files;
                if (photoFiles && photoFiles.length > 0) {
                    // 获取目前上传的文件
                    var file = photoFiles[0]; // 文件大小校验的动作
                    this.$fly
                        .putFile(file, (ossObject) => {
                            (this.speedProgress = ossObject.progress),
                                (this.ossClient = ossObject.ossClient);
                        })
                        .then((res) => {
                            this.editGuidanceByInfo.accessory.push({
                                name: res.fileName,
                                url: res.url,
                            });
                            console.log(this.editGuidanceByInfo.accessory);
                        });
                    event.target.value = "";
                }
            },

            // 添加/修改指南
            addEditGuidance(state, id, item) {
                if (state === 1) {
                    this.addEditState = 1;
                    this.addGuidanceDialogTitle = "添加办事指南";
                    this.editGuidanceVisible = true;
                } else {
                    this.addEditState = 2;
                    this.editGuidanceId = id;
                    this.editGuidance(id, item);
                    this.addGuidanceDialogTitle = "修改指南";
                }
            },
            // 点击标题查询详情
            async queryGuidanceInfo(item) {
                // this.guidanceDetailsInfo =item.text;
                 this.guidanceDetailsInfo.headline = item.title;
                // this.guidanceInfoVisible = true;
                this.guidanceDetailsInfo.content = item.text;
                this.guidanceDetailsInfo.files = item.fileUrl.split(",");
                this.guianceInfoVisible = true;
            },

            //查询指南标题
            async queryGuidance(pageNum = 1, pageSize = 10) {
                this.$fly
                    .post(showAllUrl, {
                        regionCode: this.$vc.getCurrentRegion().code, //区域code 必填
                        startTime: this.startTime, //查询开始时间 必填
                        endTime: this.endTime, //查询结束时间 必填
                        title: this.keyword, //搜索标题
                        pageNum,
                        pageSize,
                    })
                    .then((res) => {
                        if (res.code != 0) {
                            return;
                        }
                        if (pageNum === 1) {
                            this.$vc.emit(this.$route.path, "pagination", "init", {
                                total: res.data.total,
                                currentPage: pageNum,
                                pageSize: pageSize,
                            });
                        }
                        this.noticeList = res.data.list;
                    });
            },
            // 修改办事指南接口
            async editGuidance(id, item) {
                let guidanceInfo = {
                    id: item.id,
                    headline: item.title,
                    releaseDate: item.uploadTime,
                    fileSource: item.address,
                    content: item.text,
                    accessory: item.fileUrl
                        ? item.fileUrl.split(",").map((item) => {
                            let arr = item.split("/");
                            return {
                                url: item,
                                name: arr[arr.length - 1],
                            };
                        })
                        : [],
                };
                this.editGuidanceByInfo = guidanceInfo;

                let emit = setTimeout(() => {
                    this.$vc.emit(
                        this.$route.path,
                        "wangEditor",
                        "setWangEditorContent",
                        item.text
                    );
                    clearTimeout(emit);
                });
                this.editGuidanceVisible = true;
            },
            //修改
            seaveBtn() {
                const params = {
                    id: this.editGuidanceByInfo.id,
                    regionCode: this.$vc.getCurrentRegion().communityId,
                    text: this.editGuidanceByInfo.content,
                    uploadTime: this.editGuidanceByInfo.releaseDate,
                    address: this.editGuidanceByInfo.fileSource,
                    title: this.editGuidanceByInfo.headline,
                    fileUrl: this.editGuidanceByInfo.accessory.map((item) => item.url),
                };
                this.$fly.put(uploadSelectDataUrl, params).then((res) => {
                    if (res.code !== 0) {
                        return;
                    }
                    this.editGuidanceVisible = false;
                    Object.keys(this.guidance).forEach((key) => (this.guidance[key] = ""));
                    this.$vc.toast("修改指南成功");
                    this.queryGuidance();
                });
            },
            // 添加修改弹窗保存时判断
            judgeAddEdit() {
                this.addEditState === 1 ? this.addGuidance() : this.seaveBtn(true);
            },
            addEditClose() {
                this.editGuidanceByInfo = {
                    headline: "",
                    releaseDate: "",
                    fileSource: "",
                    content: "",
                    accessory: [],
                };
                this.editGuidanceVisible = false;
            },

            // 弹窗删除
            deleteGuidanceFile(index) {
                this.editGuidanceByInfo.accessory.splice(index, 1);
            },

            // 添加办事指南
            addGuidance() {
                const params = {
                    id: null,
                    regionCode: this.$vc.getCurrentRegion().communityId,
                    text: this.editGuidanceByInfo.content,
                    uploadTime: this.editGuidanceByInfo.releaseDate,
                    address: this.editGuidanceByInfo.fileSource,
                    title: this.editGuidanceByInfo.headline,
                    fileUrl: this.editGuidanceByInfo.accessory.map((item) => item.url),
                };
                this.$fly.post(insertDataUrl, params).then((res) => {
                    if (res.code !== 0) {
                        return;
                    }
                    this.editGuidanceVisible = false;
                    Object.keys(this.guidance).forEach((key) => (this.guidance[key] = ""));
                    this.$vc.toast("添加指南成功");
                    this.queryGuidance();
                });
            },

            // 删除指南
            deleteGuidance() {
                this.deleteGuidance = true;
                this.$fly
                    .post(deleteGuidanceUrl, {
                        regionCode: this.$vc.getCurrentRegion().code, //区域code 必填
                        id: this.deleteId, //指南id
                    })
                    .then((res) => {
                        if (res.code != 0) {
                            return;
                        }
                        this.deleteGuidance = false;
                        this.queryGuidance();
                    });
            },
            getFileName(fileUrl) {
                console.log(fileUrl || JSON.stringify(fileUrl))
                let appU = fileUrl.split('/');
                return appU[appU.length - 1];
            }
        },
    };
</script>

<style lang="stylus" scoped>
    .notice_title {
        display: inline-block;
        font-size: 24px;
        color: #000;
        text-align: right;
        margin-right: 40px;
        vertical-align: middle;
    }

    .enclosure {
        font-size: 24px;
        display: flex;
        align-items: flex-start;

        .enclosure_label {
            width: 48px;
            margin-right: 40px;
        }
    }

    .dialog-content {
        padding: 30px;

        .dialog-form {
            &-field {
                display: flex;
                flex-flow: row nowrap;
                justify-content: flex-start;
                align-items: flex-start;
                font-size: 24px;

                &:not(:last-of-type) {
                    margin-bottom: 30px;
                }

                .field-name {
                    width: 240px;
                    margin-right: 40px;
                    text-align: right;
                    flex: 0 0 240px;
                }

                input {
                    border: 1px solid #979797;
                    border-radius: 4px;
                }

                .show-rule {
                    display: flex;
                    flex-flow: row nowrap;

                    // align-items center

                    &-name {
                        width: 120px;
                        flex: 0 0 120px;
                        margin-right: 40px;
                    }
                }
            }
        }

        .tips {
            display: flex;
            font-size: 20px;
            color: #999;
            flex-flow: row nowrap;
            justify-content: flex-start;
            margin-top: 16px;

            .icon {
                margin-top: 4px;
                margin-right: 10px;
            }

            p {
                margin-bottom: 0;
                line-height: 1.2;

                &:not(:last-of-type) {
                    margin-bottom: 4px;
                }
            }
        }
    }

    .accessory {
        color: #1dafff;
        text-decoration: underline;
        max-width 90%
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: block;
    }
  .dateTime ::placeholder{  //通过这个设置
    color: #999; //如果有时不生效 有可能是权限不够 加一个!important 即可
  }
</style>
