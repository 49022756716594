<template>
    <!-- 下拉选择赛选框 -->
    <div class="drop-container">
        <input
                type="text"
                class="readonly-input"
                placeholder="请选择"
                v-model="selectInput"
                @click="readonlyBtn"
                :disabled="!isDisable"
        />

        <div v-show="queryListShow" @click.stop="queryListShow = true">
            <div
                    class="location-panel"
                    :style="{ left: leftPossion ? leftPossion : '' }"
            >
                <div class="location-search">
                    <input
                            type="text"
                            :placeholder="placeholderName"
                            class="location-input"
                            v-model="queryInput"
                    />
                    <button class="btn btn-primary font" @click="getAllList">查询</button>
                </div>
                <div v-if="!isEdit" style='margin-top:2px'>
                    <svg
                            class="icon"
                            aria-hidden="true"
                            style="font-size: 20px; display: inline-block;width:20px;height:20px;border:none;margin-right:5px"
                    >
                        <use xlink:href="#icon-menua-zu92"></use>
                    </svg>
                    <span style="font-size: 20px;line-height:0px;color:#999999;">
            {{`如果没有可选${searchCompanyOwner == 1 ?'企业':'个人'}，请联系负责人添加。`}}
          </span>
                </div>
                <!-- 下拉框内容 -->
                <div class="location-result" v-if="this.type === 'deputy'">
                    <p
                            class="p"
                            v-for="item in dataList"
                            :key="item.id"
                            @click.stop="selectWarte($event, item)"
                    >
                        {{ `${item.userName}-${GENDER[item.gender]}-${item.phone}` }}
                    </p>
                </div>
                <div class="location-result" v-else-if="this.type === 'lease'">
                    <p
                            class="p"
                            v-for="item in dataList"
                            :key="item.id"
                            @click.stop="selectWarte($event, item)"
                    >
                        {{ `${item.name}-${item.deputyName}-${item.deputyPhone}` }}
                    </p>
                </div>
                <div class="location-result" v-else-if="this.type === 'tenant'">
                    <p
                            class="p"
                            v-for="item in dataList"
                            :key="item.id"
                            @click.stop="selectWarte($event, item)"
                    >
                        {{ `${item.name}-${item.deputyName}-${item.deputyPhone}` }}
                    </p>
                </div>
                <div class="location-result" v-else-if="this.type === 'owner'">
                    <p
                            class="p"
                            v-for="item in dataList"
                            :key="item.id"
                            @click.stop="selectWarte($event, item)"
                    >
                        {{ `${item.name}-${item.deputyName}-${item.deputyPhone}` }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {queryLesseeExists} from "@/requestUrl";

    import CSSelect from "@/components/common/CSSelect";

    const GENDER = {
        0: "未知",
        1: "男",
        2: "女",
    };
    export default {
        name: "DropDownSelection",
        components: {
            CSSelect,
        },
        props: {
            placeholderName: {
                type: String,
                default: "请选择",
            },
            dataList: {
                type: Array,
                default: [],
            },
            item: {
                type: Object,
                default: {},
            },
            searchCompanyOwner: {
                type: String,
                default: '1'
            },
            isDisable:{
              type:Boolean,
              default:true
            },
            type: {
                type: String,
                default: "",
            },
            leftPossion: {
                type: String,
                default: "",
            },
            isEdit: {
                type: Boolean,
                default: true
            },
            selectInputKey:{
                type:String,
                default:''
            }
        },
        data() {
            return {
                GENDER,
                selectInput: "", //搜索框内容
                queryInput: "", //查询框内容
                queryListShow: false,
            };
        },
        mounted() {

            document.getElementsByClassName("oppositeName")[0].addEventListener(
                "click",
                (e) => {
                    this.queryListShow = false;
                },
                true
            );
            this.echoContainer();
        },
        created() {
            this.selectInput = this.selectInputKey;
        },
        methods: {
            //点击弹出下拉框事件
            readonlyBtn() {
                this.queryListShow = true;
            },
            //点击查询按钮
            getAllList() {
                this.$emit("queryInfo", this.queryInput);
            },
            //点击每项触发事件
            async selectWarte(e, item) {
                switch (this.type) {
                    case "deputy": {
                        this.selectInput = `${item.userName}-${GENDER[item.gender]}-${
                            item.phone
                        }`;
                    }
                        break;
                    case "lease": {
                        let res = await this.$fly.get(queryLesseeExists, {
                            type: 2,
                            id: item.id,
                        });
                        if (res.code != 0) {
                            this.selectInput = "";
                            item = {};
                            this.$emit("selectWarte", item);
                            return;
                        } else {
                            this.selectInput = `${item.name}-${item.deputyName}-${item.deputyPhone}`;
                        }
                    }
                        break;
                    case "owner": {
                        let res = await this.$fly.get(queryLesseeExists, {
                            type: 1,
                            id: item.id,
                        });
                        if (res.code != 0) {
                            this.selectInput = "";
                            item = {};
                            this.$emit("selectWarte", item);
                            return;
                        } else {
                            this.selectInput = `${item.name}-${item.deputyName}-${item.deputyPhone}`;
                        }
                    }
                        break;
                    case 'tenant': {
                        let res = await this.$fly.get(queryLesseeExists, {
                            type: 2,
                            id: item.id,
                        });
                        if (res.code != 0) {
                            this.selectInput = "";
                            item = {};
                            this.$emit("selectWarte", item);
                            return;
                        } else {
                            this.selectInput = `${item.name}-${item.deputyName}-${item.deputyPhone}`;
                        }
                    }
                        break;
                    default:
                        break;
                }
                this.$emit("selectWarte", item);
            },
            //显示回显
            echoContainer() {
                var item = this.item;
                switch (this.type) {
                    case "deputy": {
                        this.selectInput = `${item.deputyName}-${
                            this.GENDER[item.deputyGender]
                        }-${item.deputyPhone}`;
                    }
                        break;
                    case "lease": {
                        if (!item) {
                            this.selectInput = `${item.name}-${item.deputyName}-${item.deputyPhone}`;
                        }
                    }
                        break;
                    case "owner": {
                        if (!item) {
                            this.selectInput = `${item.name}-${item.deputyName}-${item.deputyPhone}`;
                        }
                    }
                        break;
                    case "tenant": {
                        console.log(item);
                        if (!item) {
                            this.selectInput = `${item.name}-${item.deputyName}-${item.deputyPhone}`;
                        }
                    }
                        break;
                    default:
                        break;
                }
            },
        },
    };
</script>

<style scoped>
    .drop-container {
        width: 345px;
    }

    .readonly-input {
        border-radius: 4px;
        border: 1px solid #999;
        padding: 0 10px;
        border: unset;
        font-size: 24px;
        outline: none;
        width: 100%;
    }

    .location-result {
        max-height: 400px;
        overflow-y: auto;
        padding: 10px 0;
    }

    .location-panel {
        position: absolute;
        background: #fff;
        z-index: 9;
        top: 50px;
        left: 0;
        border-radius: 10px;
        box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
        width: var(--location-panel-width, 370px);
        padding: 10px;
    }

    .font {
        font-size: 20px;
        vertical-align: middle;
        float: right;
        height: 40px;
        padding: 0;
        width: 60px;
        line-height: 40px;
        box-shadow: 0 5px 10px 0 rgba(0, 182, 148, 0.3);
    }

    .location-search {
        width: 100%;
    }

    .location-search .location-input {
        border-radius: 4px;
        border: 1px solid #999;
        padding: 0 10px;
        vertical-align: middle;
        width: 280px;
        height: 40px;
        box-sizing: border-box;
    }

    .p {
        border-bottom: 1px solid #f0f0f0;
        color: #000 !important;
    }
</style>
